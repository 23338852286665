var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12",
      "sm": "12"
    }
  }, [_c('b-row', [_c('b-col', [_c('h4', {
    staticClass: "float-left"
  }, [_vm._v(" " + _vm._s(_vm.options.title) + " ")]), _vm._t("fullDataLink")], 2)], 1), _c('b-row', [_c('b-col', [_vm._v(_vm._s(_vm.options.subtitle))])], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "opacity": 0.5,
      "spinner-variant": "secondary",
      "rounded": "sm"
    }
  }, [_vm.data.length === 0 && _vm.serverOptions.filter.length === 0 ? _vm._t("afterFilter") : _vm._e(), _vm.data.length === 0 && _vm.serverOptions.filter.length === 0 ? _c('div', {
    staticClass: "p-3"
  }, [_vm._v(" No data available ")]) : _vm._e(), _c('div', {
    attrs: {
      "id": "card"
    }
  }, [_vm.componentKey || _vm.serverOptions.filter.length > 0 ? _c('v-client-table', {
    key: _vm.componentKey,
    ref: "table",
    attrs: {
      "data": _vm.dataSet,
      "columns": _vm.columns,
      "name": _vm.name,
      "options": _vm.ct_options
    },
    on: {
      "row-click": _vm.onRowClick,
      "loaded": _vm.onLoad,
      "filter": _vm.onFilter,
      "sorted": _vm.onSort,
      "limit": _vm.onLimit,
      "pagination": _vm.onPagination
    },
    scopedSlots: _vm._u([_vm._l(_vm.options.editableColumns, function (columnName) {
      return {
        key: columnName,
        fn: function fn(_ref) {
          var row = _ref.row,
            update = _ref.update,
            setEditing = _ref.setEditing,
            isEditing = _ref.isEditing,
            revertValue = _ref.revertValue;
          return _vm._t(columnName, function () {
            return [!isEditing() || _vm.options.readOnly && !_vm.isInserting ? _c('div', {
              key: "".concat(_vm.$helpers.uuidv4()),
              class: " ".concat(!_vm.options.readOnly ? 'editable-cell' : ''),
              on: {
                "click": function click($event) {
                  return _vm.handleEditing(row, columnName, setEditing, revertValue);
                }
              }
            }, [Array.isArray(row[columnName]) ? _c('span', [_vm._v(_vm._s(_vm.dropdownOptionsToString(row[columnName])))]) : _c('span', [_vm._v(_vm._s(row[columnName]))])]) : _vm._e(), isEditing() && (!_vm.options.readOnly || _vm.isInserting) ? _c('div', {
              key: "".concat(_vm.$helpers.uuidv4()),
              staticClass: "editing-cell-container"
            }, [isEditing() && (_vm.isStringColumn(columnName) || _vm.isNumericColumn(columnName)) && !_vm.isDropdownColumn(columnName) && !_vm.isMaskedColumn(columnName) ? _c('input', {
              directives: [{
                name: "model",
                rawName: "v-model",
                value: row[columnName],
                expression: "row[columnName]"
              }],
              staticClass: "form-control flex-grow-1 task-name-input",
              attrs: {
                "id": "".concat(_vm.$helpers.uuidv4()),
                "type": "text"
              },
              domProps: {
                "value": row[columnName]
              },
              on: {
                "input": [function ($event) {
                  if ($event.target.composing) return;
                  _vm.$set(row, columnName, $event.target.value);
                }, function ($event) {
                  return _vm.onColumnUpdate(row[_vm.ct_options.uniqueKey], columnName, row[columnName]);
                }]
              }
            }) : _vm._e(), isEditing() && _vm.isMaskedColumn(columnName) ? _c('masked-input', {
              staticClass: "form-control flex-grow-1 task-name-input",
              attrs: {
                "id": "".concat(_vm.$helpers.uuidv4()),
                "mask": _vm.isMaskedColumn(columnName).mask,
                "placeholder": _vm.isMaskedColumn(columnName).placeholder,
                "type": "text"
              },
              on: {
                "input": function input($event) {
                  return _vm.onColumnUpdate(row[_vm.ct_options.uniqueKey], columnName, row[columnName]);
                }
              },
              model: {
                value: row[columnName],
                callback: function callback($$v) {
                  _vm.$set(row, columnName, $$v);
                },
                expression: "row[columnName]"
              }
            }) : _vm._e(), isEditing() && _vm.isDateColumn(columnName) ? _c('date-range-picker-custom', {
              attrs: {
                "single-date-picker": true,
                "ranges": false
              },
              on: {
                "input": function input($event) {
                  return _vm.onColumnUpdate(row[_vm.ct_options.uniqueKey], columnName, row[columnName]);
                }
              },
              model: {
                value: row[columnName],
                callback: function callback($$v) {
                  _vm.$set(row, columnName, $$v);
                },
                expression: "row[columnName]"
              }
            }) : _vm._e(), isEditing() && _vm.isTimeColumn(columnName) ? _c('inline-time-picker', {
              attrs: {
                "id": "".concat(_vm.$helpers.uuidv4(), "-").concat(_vm.$helpers.str2_(columnName)),
                "readonly": false,
                "mode": _vm.$constants.FORMCONTROLMODE.EDIT
              },
              on: {
                "input": function input($event) {
                  return _vm.onColumnUpdate(row[_vm.ct_options.uniqueKey], columnName, row[columnName]);
                }
              },
              model: {
                value: row[columnName],
                callback: function callback($$v) {
                  _vm.$set(row, columnName, $$v);
                },
                expression: "row[columnName]"
              }
            }) : _vm._e(), isEditing() && _vm.isDropdownColumn(columnName) ? _c('inline-select-multiple', {
              staticClass: "ct-inline-select",
              attrs: {
                "id": "".concat(_vm.$helpers.uuidv4(), "-").concat(_vm.$helpers.str2_(columnName)),
                "value": _vm.getDropdownValue(row, columnName),
                "readonly": false,
                "options": _vm.getDropdownOptions(columnName),
                "allow-empty": true,
                "mode": _vm.$constants.FORMCONTROLMODE.EDIT,
                "required": false,
                "custom-option-template": true
              },
              on: {
                "changed": function changed($event) {
                  var _vm2;
                  return (_vm2 = _vm).onChangeDropdownColumn.apply(_vm2, [columnName, row].concat(Array.prototype.slice.call(arguments)));
                },
                "open": _vm.onTableDropdownOpen,
                "close": _vm.onTableDropdownClose
              },
              scopedSlots: _vm._u([{
                key: "singleLabel",
                fn: function fn(slotProps) {
                  var _vm$getDropdownTempla;
                  return [(_vm$getDropdownTempla = _vm.getDropdownTemplates(columnName)) !== null && _vm$getDropdownTempla !== void 0 && _vm$getDropdownTempla.singleLabel ? _c({
                    template: _vm.getDropdownTemplates(columnName).singleLabel,
                    props: ['option']
                  }, {
                    tag: "component",
                    attrs: {
                      "option": slotProps.slotScope.option
                    }
                  }) : _vm._t("singleLabel", null, {
                    "slotScope": slotProps.slotScope
                  })];
                }
              }, {
                key: "option",
                fn: function fn(slotProps) {
                  var _vm$getDropdownTempla2;
                  return [(_vm$getDropdownTempla2 = _vm.getDropdownTemplates(columnName)) !== null && _vm$getDropdownTempla2 !== void 0 && _vm$getDropdownTempla2.option ? _c({
                    template: _vm.getDropdownTemplates(columnName).option,
                    props: ['option']
                  }, {
                    tag: "component",
                    attrs: {
                      "option": slotProps.slotScope.option
                    }
                  }) : _vm._t("option", null, {
                    "slotScope": slotProps.slotScope
                  })];
                }
              }], null, true)
            }) : _vm._e(), isEditing() && (!_vm.options.readOnly || _vm.isInserting) ? _c('div', {
              staticClass: "inline-buttons-container"
            }, [!_vm.isNewRecord(row) && !_vm.isDropdownColumn(columnName) ? _c('span', {
              staticClass: "btn btn-success btn-sm",
              on: {
                "click": function click($event) {
                  if (_vm.validateCell(columnName, row[columnName])) {
                    _vm.$emit('inline-update', {
                      id: row[_vm.ct_options.uniqueKey],
                      column: columnName,
                      value: row[columnName]
                    });
                    update(row[columnName]);
                    setEditing(false);
                  }
                }
              }
            }, [_c('font-awesome-icon', {
              attrs: {
                "icon": "check"
              }
            })], 1) : _vm._e(), !_vm.isNewRecord(row) && _vm.isDropdownColumn(columnName) ? _c('span', {
              staticClass: "btn btn-success btn-sm",
              on: {
                "click": function click($event) {
                  if (_vm.validateCell(columnName, row[columnName])) {
                    _vm.$emit('inline-update', {
                      id: row[_vm.ct_options.uniqueKey],
                      column: columnName,
                      value: _vm.getDropdownValue(row, columnName)
                    });
                    update(row[columnName]);
                    setEditing(false);
                  }
                }
              }
            }, [_c('font-awesome-icon', {
              attrs: {
                "icon": "check"
              }
            })], 1) : _vm._e(), !_vm.isNewRecord(row) ? _c('span', {
              staticClass: "btn btn-danger btn-sm",
              on: {
                "click": function click($event) {
                  revertValue();
                  setEditing(false);
                }
              }
            }, [_c('font-awesome-icon', {
              attrs: {
                "icon": "ban"
              }
            })], 1) : _vm._e()]) : _vm._e()], 1) : _vm._e()];
          });
        }
      };
    }), _vm._l(_vm.options.slots, function (slotName) {
      return {
        key: slotName,
        fn: function fn(props) {
          return _vm._t(slotName, null, {
            "row": props.row
          });
        }
      };
    }), {
      key: "#",
      fn: function fn(props) {
        return _vm.options.selectableRows ? _c('div', {
          attrs: {
            "name": "checkbox"
          }
        }, [_c('b-form-checkbox', {
          staticClass: "ml-1",
          attrs: {
            "id": "".concat(_vm.uid, "-").concat(_vm.name, "-cb-row-selector-").concat(props.index),
            "name": "cb-row-selector-".concat(props.index)
          },
          on: {
            "change": function change($event) {
              return _vm.onRowSelectorChange(props);
            }
          },
          model: {
            value: props.row['#'],
            callback: function callback($$v) {
              _vm.$set(props.row, '#', $$v);
            },
            expression: "props.row['#']"
          }
        })], 1) : _vm._e();
      }
    }, _vm._l(_vm.options.formatColumns, function (column, index) {
      return {
        key: column.name,
        fn: function fn(props) {
          return [column.style ? _c('span', {
            key: "cc-".concat(index)
          }, [_vm._v(" " + _vm._s((parseFloat(props.row[column.name]) || 0).toLocaleString('en-US', column.style)) + " ")]) : _vm._e(), !column.style && !column.url ? _c('span', {
            key: "cc-".concat(index)
          }, [_vm._v(" " + _vm._s(props.row[column.name]) + " ")]) : _vm._e(), column.url ? _c('span', {
            key: "cu-".concat(index)
          }, [_c('b-link', {
            attrs: {
              "href": props.row[column.name].url,
              "target": "_blank"
            }
          }, [_vm._v(_vm._s(props.row[column.name].label))])], 1) : _vm._e()];
        }
      };
    }), {
      key: _vm.options.showActions ? 'Actions' : '',
      fn: function fn(props) {
        return _vm.options.showActions ? _c('div', {
          staticClass: "default-cell",
          attrs: {
            "name": "default-actions"
          }
        }, [_c('div', {
          staticClass: "btn-group"
        }, [_c('button', {
          staticClass: "btn btn-primary btn-sm",
          on: {
            "click": function click($event) {
              return _vm.viewItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "eye"
          }
        })], 1), _c('button', {
          staticClass: "btn btn-success btn-sm",
          on: {
            "click": function click($event) {
              return _vm.editItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "pencil-alt"
          }
        })], 1), _c('button', {
          staticClass: "btn btn-danger btn-sm",
          on: {
            "click": function click($event) {
              return _vm.deleteItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "trash"
          }
        })], 1)])]) : _vm.options.showActions && props.row.isInserting ? _c('div', {
          staticClass: "default-cell"
        }, [_c('b-button-group', [_c('button', {
          staticClass: "btn btn-success btn-sm",
          attrs: {
            "disabled": props.row.isLoading
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.saveNewRecord(props.row);
            }
          }
        }, [!props.row.isLoading ? _c('font-awesome-icon', {
          attrs: {
            "icon": "save"
          }
        }) : _vm._e(), props.row.isLoading ? _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow"
          }
        }) : _vm._e()], 1), _c('button', {
          staticClass: "btn btn-danger btn-sm",
          attrs: {
            "disabled": props.row.isLoading
          },
          on: {
            "click": function click($event) {
              return _vm.revertNewRecord(props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "ban"
          }
        })], 1)])], 1) : _vm._e();
      }
    }, {
      key: _vm.options.showCustomActions ? 'Actions' : '',
      fn: function fn(props) {
        return _vm.options.showCustomActions && !props.row.isInserting ? _vm._t("custom-actions", null, {
          "row": props.row
        }) : _vm.options.showCustomActions && props.row.isInserting ? _c('div', {
          staticClass: "default-cell"
        }, [_c('b-button-group', [_c('button', {
          staticClass: "btn btn-success btn-sm",
          attrs: {
            "disabled": props.row.isLoading
          },
          on: {
            "click": function click($event) {
              return _vm.saveNewRecord(props.row);
            }
          }
        }, [!props.row.isLoading ? _c('font-awesome-icon', {
          attrs: {
            "icon": "save"
          }
        }) : _vm._e(), props.row.isLoading ? _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow"
          }
        }) : _vm._e()], 1), _c('button', {
          staticClass: "btn btn-danger btn-sm",
          attrs: {
            "disabled": props.row.isLoading
          },
          on: {
            "click": function click($event) {
              return _vm.revertNewRecord(props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "ban"
          }
        })], 1)])], 1) : _vm._e();
      }
    }, {
      key: _vm.options.showChildRows ? 'child_row' : '',
      fn: function fn(props) {
        return _vm.options.showChildRows ? _vm._t("child_row", null, {
          "row": props.row
        }) : _vm._e();
      }
    }], null, true)
  }, [_vm._t("afterFilter", null, {
    "slot": "beforeLimit"
  }), !_vm.options.hideSettingsBar ? _c('div', {
    staticClass: "form-row d-flex justify-content-end",
    attrs: {
      "slot": "afterLimit"
    },
    slot: "afterLimit"
  }, [_vm.mode === 'server' ? _c('b-form-select', {
    attrs: {
      "options": _vm.serverOptions.pagination.perPageValues
    },
    on: {
      "change": _vm.onServerRowsPerPageChanged
    },
    model: {
      value: _vm.serverOptions.pagination.rowsPerPage,
      callback: function callback($$v) {
        _vm.$set(_vm.serverOptions.pagination, "rowsPerPage", $$v);
      },
      expression: "serverOptions.pagination.rowsPerPage"
    }
  }) : _vm._e(), _c('b-dropdown', {
    attrs: {
      "variant": "outline",
      "dropleft": true,
      "no-flip": "",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_vm.allColumnsVisible ? _c('font-awesome-icon', {
          attrs: {
            "icon": "cog"
          }
        }) : _vm._e(), !_vm.allColumnsVisible ? _c('font-awesome-icon', {
          attrs: {
            "icon": "tasks"
          }
        }) : _vm._e()];
      },
      proxy: true
    }], null, false, 1157146655)
  }, _vm._l(_vm.columnObjects, function (item, index) {
    return _c('b-form-checkbox', {
      key: "".concat(_vm.uid, "-").concat(index),
      staticClass: "ml-2",
      attrs: {
        "id": "".concat(_vm.uid, "-").concat(index),
        "name": "cb-column-".concat(_vm.uid, "-").concat(index),
        "value": true,
        "unchecked-value": false
      },
      on: {
        "change": function change($event) {
          return _vm.onColumnSettingsChange(item);
        }
      },
      model: {
        value: item.visible,
        callback: function callback($$v) {
          _vm.$set(item, "visible", $$v);
        },
        expression: "item.visible"
      }
    }, [_vm._v(" " + _vm._s(item.column) + " ")]);
  }), 1)], 1) : _vm._e(), _c('div', {
    attrs: {
      "slot": "filter__#"
    },
    slot: "filter__#"
  }, [_c('b-form-checkbox', {
    staticClass: "ml-1",
    attrs: {
      "id": "".concat(_vm.uid, "-").concat(_vm.name, "-cb-root-selector")
    },
    on: {
      "change": _vm.onSelectAllChange
    },
    model: {
      value: _vm.selectAll,
      callback: function callback($$v) {
        _vm.selectAll = $$v;
      },
      expression: "selectAll"
    }
  })], 1), _c('tr', {
    staticClass: "VueTables__row auto-totals",
    attrs: {
      "slot": "appendBody"
    },
    slot: "appendBody"
  }, _vm._l(_vm.columnObjects.filter(function (c) {
    return c.visible;
  }), function (item, index) {
    return _c('td', {
      key: "td-".concat(index),
      staticClass: "font-weight-bold",
      style: {
        width: item.width,
        minWidth: item.width
      },
      attrs: {
        "tabindex": "0"
      }
    }, [_vm._v(" " + _vm._s("".concat(item.total.label, " ").concat(item.total.value)) + " ")]);
  }), 0), _vm.showOverallTotals ? _c('tr', {
    staticClass: "VueTables__row auto-totals",
    attrs: {
      "slot": "appendBody"
    },
    slot: "appendBody"
  }, [_c('td', {
    attrs: {
      "colspan": "100%"
    }
  }, [_vm._v("Overall totals:")])]) : _vm._e(), _vm.showOverallTotals ? _c('tr', {
    staticClass: "VueTables__row auto-totals",
    attrs: {
      "slot": "appendBody"
    },
    slot: "appendBody"
  }, _vm._l(_vm.columnObjects.filter(function (c) {
    return c.visible;
  }), function (item, index) {
    return _c('td', {
      key: "td-".concat(index),
      staticClass: "font-weight-bold",
      style: {
        width: item.width,
        minWidth: item.width
      },
      attrs: {
        "tabindex": "0"
      }
    }, [_vm._v(" " + _vm._s("".concat(item.total.label, " ").concat(item.total.overallValue)) + " ")]);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "pb-3",
    attrs: {
      "slot": "afterTable"
    },
    slot: "afterTable"
  }, [_vm.mode === 'server' ? _c('b-pagination', {
    attrs: {
      "limit": "10",
      "total-rows": _vm.serverOptions.pagination.totalRows,
      "per-page": _vm.serverOptions.pagination.rowsPerPage
    },
    on: {
      "page-click": _vm.onServerPagination
    },
    model: {
      value: _vm.serverOptions.pagination.currentPage,
      callback: function callback($$v) {
        _vm.$set(_vm.serverOptions.pagination, "currentPage", $$v);
      },
      expression: "serverOptions.pagination.currentPage"
    }
  }) : _vm._e(), _vm._l(_vm.totals, function (item, index) {
    return _c('div', {
      key: "tt-".concat(index)
    }, [_c('strong', [_vm._v("Total " + _vm._s(item.alias) + ":")]), _vm._v(" " + _vm._s(item.value.toLocaleString('en-US', item.style)) + " ")]);
  })], 2)], 2) : _vm._e()], 1)], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }